<template>
  <Title
    v-if="loaded"
    :title="title"
    :class="isCentredTitle ? [$style.title, $style['title--centred']] : $style.title"
  />
</template>

<script>
import { mapState } from 'pinia';
import { useSubscriptionsStore, useTeamsStore } from '@/stores/index.js';
import Title from '@/components/title.vue';

export default {
  name: 'SubscriptionsTitle',
  components: {
    Title,
  },
  computed: {
    ...mapState(useTeamsStore, ['getOwnTeamSelected']),
    ...mapState(useSubscriptionsStore, [
      'getLicensePlan',
      'getLicenseIsActive',
      'getIsTrialLicense',
      'loaded',
    ]),
    title() {
      return this.getLicensePlan
        ? `${this.getLicensePlan} ${this.getIsTrialLicense ? 'subscription' : 'plan'}`
        : 'Trial subscription';
    },
    isCentredTitle() {
      return (!this.getLicensePlan || this.getIsTrialLicense || !this.getLicenseIsActive)
        && this.getOwnTeamSelected;
    },
  },
};
</script>

<style lang="scss" module>
.title {
  position: relative;

}
  @media (max-width: 768px) {
    .title {
      &--centred {
        margin: 0 auto;
      }
    }
  }
</style>
